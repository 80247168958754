import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JuresService} from '../../../../../../../data/commission/services/jures/jures.service';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {SessionContext} from '../../../../../../../core/services/config/app.settings';
import {CahiersService} from '../../../../../../../data/habilitation/services/cahiers/cahiers.service';
import {JureCdc} from '../../../../../../../data/commission/models/jure-cdc.model';
import {Cahier} from '../../../../../../../data/habilitation/models/cahier.model';
import {forkJoin} from 'rxjs';
import {OrganismesService} from '../../../../../../../data/intervenant/services/organismes/organismes.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {College} from '../../../../../../../data/commission/models/college.model';
import {CollegeService} from '../../../../../../../data/commission/services/college/college.service';


@Component({
  selector: 'app-liste-jures',
  templateUrl: './liste-jures.component.html',
  styleUrls: ['./liste-jures.component.scss']
})
export class ListeJuresComponent implements OnInit {
  jures: JureCdc[];
  jure: JureCdc;
  juresCdc: JureCdc[];
  juresCdcFiltered: JureCdc[];
  idOrganisme: number;
  order = {
    property: 'id',
    reverse: false
  };
  refColleges: College[];
  refCahiers: Cahier[];
  formJureSearch: FormGroup;

  get cdcs() {
    return this.formJureSearch.get('cdcs');
  }

  get showInactifs() {
    return this.formJureSearch.get('showInactifs');
  }

  get jureNom() {
    return this.formJureSearch.get('nomJure');
  }

  get college() {
    return this.formJureSearch.get('college');
  }

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly jureService: JuresService,
    private readonly cahierService: CahiersService,
    private readonly collegeService: CollegeService,
    private readonly organismeService: OrganismesService,
  ) {
  }

  ngOnInit() {
    this.idOrganisme = SessionContext.get('idOrganisme');
    this.formJureSearch = this.fb.group({
      college: [undefined],
      cdcs: undefined,
      nomJure: undefined,
      showInactifs: [false]
    });
    this.getJures();
    this.loadData();
    // Souscription aux changements du formulaire pour filtrer les jurés
    this.formJureSearch.valueChanges.subscribe(val => {
      this.filterJures();
    });
  }

  private loadData(): void {
    forkJoin(
      this.cahierService.getCahiers(),
      this.collegeService.getColleges(),
      this.organismeService.getOrganisme(SessionContext.get('idOrganisme')),
    ).subscribe(([cahiers, colleges]) => {
      this.refCahiers = cahiers;
      this.refColleges = colleges;

      this.setOrder('Nom');
    });
  }

  /**
   * On récupère les jurés par idOrganisme et par les id des cahiers des charges génér par l'ODG ou l'OC/OI
   */
  getJures() {
    this.cahierService.getCahiers().subscribe(cahiers => {
      const idsCahiers = cahiers.map(cahier => cahier.id);
      if (idsCahiers.length > 0) {
        forkJoin({
          actifs: this.jureService.getJuresByCdcs(this.idOrganisme, idsCahiers),
          inactifs: this.jureService.getJuresInactifsByCahiers(this.idOrganisme, idsCahiers),
        }).subscribe(({actifs, inactifs}) => {
          this.juresCdc = actifs;
          this.juresCdc.forEach(jure => {
            jure.checked = inactifs.find(jureAFiltrer => jureAFiltrer.id === jure.id) !== undefined;
          });
          this.filterJures();
        });
      }
    });
  }

  setOrder(property: string) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  /**
   * Methode pour filtrer les jurés (avec des filtres indépendants)
   */
  filterJures() {
    let filteredJures = [...this.juresCdc]; // Commence avec tous les jurés

    // Filtrage par nom
    const nom = this.jureNom.value;
    if (nom) {
      filteredJures = filteredJures.filter(jure => jure.nom.toLowerCase().includes(nom.toLowerCase()));
    }

    // Filtrage par collège
    const college = this.college.value;
    if (college) {
      filteredJures = filteredJures.filter(jure => jure.colleges.some(col => col.libelle === college.libelle));
    }

    const checked = this.showInactifs.value;
    if (!checked) {
      filteredJures = filteredJures.filter(jure => jure.checked === false);
    }
    // Filtrage par cahiers des charges
    const cdcs = this.cdcs.value;
    if (cdcs && cdcs.length > 0) {
      const cdcsLibelles = cdcs.map(cdc => cdc.libelle);
      filteredJures = filteredJures.filter(jure => {
        const jureCdcsLibelle = jure.cahiers.map(cdc => cdc.libelle);
        return cdcsLibelles.every(selectedLibelle => jureCdcsLibelle.includes(selectedLibelle));
      });
    }

    // Mise à jour de la liste des jurés à afficher
    this.juresCdcFiltered = filteredJures;
  }

  getIcon(property: string) {
    if (property === this.order.property) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }

  public getLibellesColleges(jure: any): string {
    return jure.colleges.map(college => college.libelle).join(', ');
  }

  public getRaisonSocialeOperateurs(jure: any): string {
    return jure.raisonSocialesOperateurs ? jure.raisonSocialesOperateurs.join(', ') : '';
  }

  get selectedJure() {
    return this.formJureSearch.get('jure').value as JureCdc;
  }

  deleteJure(jureId: number) {
    this.jureService.deleteAllComQualif(jureId).subscribe(
      () => {
        console.log('juré rendue inactif');
        window.location.reload(); // Méthode pour rafraîchir les données
      },
      error => {
        console.error('Error : le juré est toujours actif', error);
      }
    );
  }
}
